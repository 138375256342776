import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { addCheckoutId, checkoutStepYou } from './checkoutSlice';

import './CheckoutStep.css';
import BottomBar from './BottomBar';
import callBackend from '../utils/callBackend';

const CheckoutStepYou = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState(useSelector(state => state.checkout.firstName));
    const [lastName, setLastName] = useState(useSelector(state => state.checkout.lastName));
    const [clubName, setClubName] = useState(useSelector(state => state.checkout.clubName));
    const [birthYear, setBirthYear] = useState(useSelector(state => state.checkout.birthYear));
    const [email, setEmail] = useState(useSelector(state => state.checkout.email));

    const id = useSelector(state => state.checkout.id);
    const flow = useSelector(state => state.checkout.flowName);

    const changeFirstNameHandler = event => {
        setFirstName(event.target.value);
    };

    const changeLastNameHandler = event => {
        setLastName(event.target.value);
    };

    const changeClubNameHandler = event => {
        setClubName(event.target.value);
    };

    const changeBirthYearHandler = event => {
        setBirthYear(event.target.value);
    };

    const changeEmailHandler = event => {
        setEmail(event.target.value);
    };

    const submitStep = () => {
        const DATA = { firstName, lastName, clubName, birthYear, email };
        dispatch(checkoutStepYou(DATA));
        if( flow === 'B2C' ) {
            callBackend('checkout',
                { id, you: DATA }, 
                result => { if( result.isOk ) dispatch(addCheckoutId(result.data.id)); }
            );
        }
    };

    const canSubmit = firstName && lastName && email;
    const optionalClub = flow === 'REG' ? <tr>
        <td className='CheckoutStep'>{localize(lang, 'clubName')}:</td>
        <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeClubNameHandler} value={clubName} /></td>
    </tr> : '';
    const optionalBirthYear = flow === 'REG' ? <tr>
        <td className='CheckoutStep'>{localize(lang, 'birthYear')}:</td>
        <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeBirthYearHandler} value={birthYear} /></td>
    </tr> : '';

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table className='CheckoutStep'>
                    <tbody>
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'firstName')}:</td>
                            <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeFirstNameHandler} value={firstName} /></td>
                        </tr>
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'lastName')}:</td>
                            <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeLastNameHandler} value={lastName} /></td>
                        </tr>
                        {optionalClub}
                        {optionalBirthYear}
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'email')}:</td>
                            <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeEmailHandler} value={email} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} canSubmit={canSubmit} submitStep={submitStep} />
        </div>);
}

export default CheckoutStepYou