import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';
import { checkoutStep } from '../checkoutSlice';

import '../CheckoutStep.css';
import BottomBar from '../BottomBar';
import callBackend from '../../utils/callBackend';

// Registration
const CheckoutSummary = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const checkoutData = useSelector(state => state.checkout);

    const submit = () => {
        const data = {
            firstName: checkoutData.firstName,
            lastName: checkoutData.lastName,
            email: checkoutData.email,
            clubName: checkoutData.clubName,
            birthYear: checkoutData.birthYear,
            age: checkoutData.age,
            gender: checkoutData.gender
        }

        const toSubmit = [];
        if(checkoutData.weaponE) {
            toSubmit.push( {...data, weapon: 'epee'} );
        }
        if(checkoutData.weaponF) {
            toSubmit.push( {...data, weapon: 'foil'} );
        }
        if(checkoutData.weaponS) {
            toSubmit.push( {...data, weapon: 'sabre'} );
        }

        callBackend('register', { items: toSubmit });
        dispatch(checkoutStep(''));
    };

    const weapon = [
        useSelector(state => state.checkout.weaponE ? localize(lang, 'epee') : ''),
        useSelector(state => state.checkout.weaponF ? localize(lang, 'foil') : ''),
        useSelector(state => state.checkout.weaponS ? localize(lang, 'sabre') : '')
    ];
    const weaponString = weapon.filter( w => w !== '' ).join(', ');

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table>
                    <tbody>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'yourName')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.firstName + ' ' + checkoutData.lastName}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'email')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.email}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'clubName')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.clubName}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'competitionType')}:</td>
                            <td  className='CheckoutStep-summary'>{localize(lang, checkoutData.gender) + ' ' + weaponString + ' ' + localize(lang, checkoutData.age) + ' (' + localize(lang, 'birthYear') + ' ' + checkoutData.birthYear + ')'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} submitStep={submit} isSubmit={true} />
        </div>);
}

export default CheckoutSummary