import { createSlice } from '@reduxjs/toolkit'

const YOU = 'YOU';

const B2C_PACKAGE = 'B2C_PACKAGE';
const B2C_SUMMARY = 'B2C_SUMMARY';
const B2C_THANK_YOU = 'B2C_THANK_YOU';

const REG_EVENT = 'REG_EVENT';
const REG_SUMMARY = 'REG_SUMMARY';
const REG_THANK_YOU = 'REG_THANK_YOU';

const CHECKOUT_FLOW_B2C = {
    YOU: B2C_PACKAGE,
    B2C_PACKAGE: B2C_SUMMARY,
    B2C_SUMMARY: B2C_THANK_YOU
};

const CHECKOUT_FLOW_REG = {
    YOU: REG_EVENT,
    REG_EVENT: REG_SUMMARY,
    REG_SUMMARY: REG_THANK_YOU
};

const INITIAL_STATE_CHECKOUT = {
    offering: [],
    flow: null,
    flowName: null,
    context: null,
    id: null,
    step: YOU,

    firstName: '',
    lastName: '',
    clubName: '',
    birthYear: '',
    email: '',

    age: '',
    gender: '',
    weaponE: false,
    weaponF: false,
    weaponS: false,

    product: ''
};

export const checkoutSlice = createSlice({
  name: 'checkout',

  initialState: INITIAL_STATE_CHECKOUT,

  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

    clear: () => INITIAL_STATE_CHECKOUT,

    addCheckoutFlow: (state, action) => {
        state.flowName = action.payload;
        switch( action.payload ) {
            case 'B2C':
                state.flow = CHECKOUT_FLOW_B2C;
                break;
            case 'REG':
                state.flow = CHECKOUT_FLOW_REG;
                break;
            default:
                state.flow = null;
        }
    },

    addCheckoutId: (state, action) => {
        state.id = action.payload;
    },

    addCheckoutContext: (state, action) => {
        state.context = action.payload;
    },

    addOffering: (state, action) => {
        state.offering = action.payload;
    },

    checkoutStepYou: (state, action) => {
        state.step = state.flow[state.step];
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.clubName = action.payload.clubName;
        state.birthYear = action.payload.birthYear;
        state.email = action.payload.email;
    },

    checkoutStepTournament: (state, action) => {
        state.step = state.flow[state.step];
        state.age = action.payload.age;
        state.gender = action.payload.gender;
        state.weaponE = action.payload.weaponE;
        state.weaponF = action.payload.weaponF;
        state.weaponS = action.payload.weaponS;
    },

    checkoutStepPackage: (state, action) => {
        state.step = state.flow[state.step];
        state.product = action.payload.product;
    },

    checkoutStep: state => {
        state.step = state.flow[state.step];
    },

    checkoutGoToStep: (state, action) => {
        state.step = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { clear, addCheckoutFlow, addCheckoutId, addCheckoutContext, addOffering, checkoutStepYou, checkoutStepTournament, checkoutStepPackage, checkoutStep, checkoutGoToStep } = checkoutSlice.actions
export { INITIAL_STATE_CHECKOUT }

export default checkoutSlice.reducer