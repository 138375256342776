import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';
import { checkoutStepTournament } from '../checkoutSlice';

import '../CheckoutStep.css';
import BottomBar from '../BottomBar';

const CheckoutStepTournament = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const [age, setAge] = useState(useSelector(state => state.checkout.age));
    const [gender, setGender] = useState(useSelector(state => state.checkout.gender));
    const [weaponE, setWeaponE] = useState(useSelector(state => state.checkout.weaponE));
    const [weaponF, setWeaponF] = useState(useSelector(state => state.checkout.weaponF));
    const [weaponS, setWeaponS] = useState(useSelector(state => state.checkout.weaponS));

    const changeAgeHandler = event => {
        setAge(event.target.value);
    };

    const changeGenderHandler = event => {
        setGender(event.target.value);
    };

    const changeWeaponEHandler = () => {
        setWeaponE(!weaponE);
    };

    const changeWeaponFHandler = () => {
        setWeaponF(!weaponF);
    };

    const changeWeaponSHandler = () => {
        setWeaponS(!weaponS);
    };

    const submitStep = () => {
        const DATA = { age, gender, weaponE, weaponF, weaponS };
        dispatch(checkoutStepTournament(DATA));
    };

    const canSubmit = age && gender && (weaponE || weaponF || weaponS);

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table className='CheckoutStep'>
                    <tbody>
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'age')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='over30'
                                        checked={age === 'over30'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'over30')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='over40'
                                        checked={age === 'over40'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'over40')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='over50'
                                        checked={age === 'over50'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'over50')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='over60'
                                        checked={age === 'over60'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'over60')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='over70'
                                        checked={age === 'over70'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'over70')}
                                </label>
                            </td>
                        </tr>


                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'gender')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='men'
                                        checked={gender === 'men'}
                                        onChange={changeGenderHandler} />
                                    {localize(lang, 'men')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='women'
                                        checked={gender === 'women'}
                                        onChange={changeGenderHandler} />
                                    {localize(lang, 'women')}
                                </label>
                            </td>
                            <td className='CheckoutStep' />
                        </tr>

                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'weapon')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='checkbox' 
                                        value='epee'
                                        checked={weaponE}
                                        onChange={changeWeaponEHandler} />
                                    {localize(lang, 'epee')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='checkbox' 
                                        value='foil'
                                        checked={weaponF}
                                        onChange={changeWeaponFHandler} />
                                    {localize(lang, 'foil')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='checkbox' 
                                        value='sabre'
                                        checked={weaponS}
                                        onChange={changeWeaponSHandler} />
                                    {localize(lang, 'sabre')}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} canSubmit={canSubmit} submitStep={submitStep} />
        </div>);
}

export default CheckoutStepTournament