import React, { useContext } from "react";
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import '../CheckoutStep.css';
import BottomBar from '../BottomBar';

const CheckoutThankYou = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                {localize(lang, 'nextStepRegistration')}
            </div>
            <BottomBar cancelCheckout={cancelCheckout} dismiss={true} />
        </div>);
}

export default CheckoutThankYou