import configuration from "../configuration";

const callBackend = (callName, callData, callBack) => {

    const request = callData === null ? {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        }
      } : {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(callData)
      };


    switch (callName) {
        case 'tournament':
        case 'mainPage':
        case 'results':
        case 'analytics':
        case 'seriesHighlights':
        case 'olympics':
        case 'search':            // Search
        case 'fencer':
        case 'snapshot':
        case 'opponent':
        case 'checkout':          // Checkout
        case 'submit':
        case 'register':
        case 'products':
        case 'languagePack':      // Translations
        case 'languagePacks':
        case 'isAdmin':           // Admin
        case 'tournamentAdmin':
        case 'seriesAdmin':
        case 'createTournament':
        case 'setRainbow':
        case 'setSeriesName':
        case 'setEventDate':
        case 'setEventQuality':
        case 'setEventSeries':
        case 'dropEventSeries':
        case 'eventAction':
        case 'updateTournament':
        case 'listNameMapping':
        case 'approveNameMapping':
        case 'updateNameMapping':
        case 'translate':
        case 'fixTranslation':
        case 'addLanguage':
        case 'makeHighlights':
            fetch( configuration.BACKEND_URL + '/' + callName, request)
                .then( response => {
                    if( ! response.ok ) {
                        throw new Error('Backend call ' + callName + ' responded with ' + response.statusText);
                    }
                    return response.json();
                })
                .then( data => {
                    const result = { isOk: true, data };

                    try {
                        if(callBack) callBack( result );
                    } catch ( error ) {
                        console.error("Callback for backend call " + callName + " failed: " + error.message);
                    }
                })
                .catch( error => {
                    const result = { isOk: false, error: error.message };

                    try {
                        if(callBack) callBack( result );
                    } catch ( error ) {
                        console.error("Callback for backend call " + callName + " failed: " + error.message);
                    }
                } );
            break;

        default:
            console.error("Backend call " + callName + " is not supported");
    }
}

export default callBackend;