import React, { useState, useContext } from 'react';

import localize from '../i18n/localize';

import LocalizedContext from '../i18n/LocalizedContext';
import ReactGA from "react-ga4";

import './SeriesContainer.css';

import FencerDisplay from './controls/FencerDisplay';
import OneSerie from './series/OneSerie';
import Series from './series/Series';
import SearchFencer from './search/SearchFencer';
import Highlights from './highlights/Highlights';
import callBackend from '../utils/callBackend';
import SeriesSummary from './highlights/SeriesSummary';
import Registration from './search/Registration';

const NO_EVENT = 'no event';

const SeriesContainer = props => {
    const FENCER = 'FENCER';
    const SERIES = 'SERIES';

    const series = props.data.series;
    const organizations = props.data.organizations;
    const highlights = props.data.highlights;
    const selectionMade = props.selectionMade;
    const organization = props.organization;
    const setOrganizationHandler = props.setOrganizationHandler;
    const chooseContentHandler = props.chooseContentHandler;
    const lang = useContext(LocalizedContext);

    const [event, setEvent] = useState(NO_EVENT);
    const [selectedSerie, setSelectedSerie] = useState(null);
    const [fencerEvents, setFencerEvents] = useState([]);
    const [selectedFencer, setSelectedFencer] = useState(null);
    const [selectedSerieSummary, setSelectedSerieSummary] = useState(null);

    var fencerEventsIsLoading = false;

    const selectedFencerHandler = fencer => {
        setSelectedFencer(fencer);
    }

    const setEventHandler = eventObject => {
        chooseContentHandler();
        setEvent(eventObject);
        setSelectedSerieSummary(null)
        setSelectedSerie(null);
        setFencerEvents([]);
        ReactGA.event( 'select_content', {
            content_type: 'event_series',
            item_id: eventObject.eventPk.toString()
        });
    };

    const setSeriesSummaryHandler = serie => {
        chooseContentHandler();
        setSelectedSerieSummary(serie)
        setEvent(null);
        setSelectedSerie(null);
        setFencerEvents([]);
        ReactGA.event( 'select_content', {
            content_type: 'series_summary',
            item_id: serie.seriesPk.toString()
        });
    };

    // User input handling
    const setSeriesHandler = s => {
        setSelectedSerie(s);
        setFencerEvents([]);
        setSelectedFencer(null);
        if( s !== null ) localStorage.setItem(SERIES, JSON.stringify(s));
    };

    const findPreviousSeries = () => {
        const previousSeriesJSON = localStorage.getItem(SERIES);
        if( previousSeriesJSON ) {
            const previousSeries = JSON.parse(previousSeriesJSON);
            return series.find( s =>     // need to get them fresh, specially the events
                s.age === previousSeries.age &&
                s.competition === previousSeries.competition &&
                s.gender === previousSeries.gender &&
                s.organization === previousSeries.organization &&
                s.weapon === previousSeries.weapon );
        } else {
            return null;
        }
    };

    const setFencerHandler = fencer => {
        if( fencer && !fencerEventsIsLoading) {
            fencerEventsIsLoading = true;
            callBackend('fencer', { term: fencer, maxResults: 16 }, result => {
                if( result.isOk ) {
                    if(fencerEventsIsLoading) {
                        setSelectedSerie(null);
                        setFencerEvents(result.data);
                        setSelectedFencer(fencer);
                        localStorage.setItem(FENCER, fencer);
                    }
                }
                fencerEventsIsLoading = false;
            });
        } else {
            setFencerEvents([]);
            setSelectedFencer(null);
            fencerEventsIsLoading = false;
        }
    };

    // Display...
    const description = 
        <div>
            <div className='SeriesContainer-header'>
                {localize(lang, 'fencingStats')}
            </div>
            <div className='SeriesContainer-text'>
                {localize(lang, 'fencingStatsDetail')}
            </div>
        </div>;

    const previousFencer = localStorage.getItem( FENCER ) || '';

    if( ! selectionMade || (event === NO_EVENT) ) {
        const highlightedEvent = highlights ? series.flatMap( s => s.events ).find( e => e.eventPk === highlights.eventPk ) : null;
        const setSelectedHighlightHandler = () => {
            if( highlightedEvent ) {
                selectedFencerHandler( highlights.fencer );
                setEventHandler( highlightedEvent );
            }
        };

        return (
            <div>
                <Registration />
                <SearchFencer setFencerHandler={setFencerHandler} previousFencer={previousFencer}>
                    <OneSerie events={fencerEvents} setEventHandler={setEventHandler}/>
                </SearchFencer>
                <Series series={series} setSeriesHandler={setSeriesHandler} previousSeries={findPreviousSeries()}
                    organization={organization} setOrganizationHandler={setOrganizationHandler} organizations={organizations} >
                    {selectedSerie ? 
                        <OneSerie events={selectedSerie.events}
                                setEventHandler={setEventHandler}
                                selectedSerie={selectedSerie} 
                                setSeriesSummaryHandler={ () => setSeriesSummaryHandler(selectedSerie) }
                        /> : ''}
                </Series>
                {description}
                { highlights ? <Highlights highlights={highlights} highlightedEvent={highlightedEvent}
                    setSelectedHighlightHandler={setSelectedHighlightHandler} /> : '' }
            </div>
        );
    } else if( selectedSerieSummary ) {
        return (
            <SeriesSummary series={selectedSerieSummary} />
        );
    } else {
        const thisSeries = series.filter( s => s.events.map( e => e.eventPk ).includes( event.eventPk ) );
        const otherEvents = thisSeries.length > 0 ? thisSeries[0].events : [];
        const isIndividual = thisSeries.length > 0 ? thisSeries[0].competition === 'individual' : false;

        return (
            <FencerDisplay
                event={event}
                otherEvents={otherEvents}
                setEventHandler={setEventHandler}
                selectedFencer={selectedFencer}
                selectedFencerHandler={selectedFencerHandler}
                isIndividual={isIndividual}
            />
        );
    }
}

export default SeriesContainer;