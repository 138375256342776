import React, { useState, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './OrganizationPicker.css';

import OrganizationSelector from './OrganizationSelector';

const OrganizationPicker = props => {
    const availableOrganizations = props.availableOrganizations;
    const organizationHandler = props.organizationHandler;
    const currentOrganization = props.currentOrganization;
    const lang = useContext(LocalizedContext);

    const organizationMap = {
        fie: {
            img: '/org/fie.png',
            key: 'fie-cup'
        },
        czech: {
            img: '/org/cff.png',
            key: 'cff-cup'
        },
        veteran: {
            img: '/org/evfc.png',
            key: 'evfc-cup'
        }
    };

    const [isPicking, setPicking] = useState(false);

    const organizationSelectedHandler = org => {
        organizationHandler(org);
        setPicking(false);
    };

    const orgData = organizationMap[currentOrganization];

    const pickingModal = isPicking ?
        <OrganizationSelector
            availableOrganizations={availableOrganizations}
            organizationSelectedHandler={organizationSelectedHandler}
            cancelHandler={() => setPicking(false)}
            organizationMap={organizationMap} /> : '';

            return (
        <div className='OrganizationPicker'>
            {pickingModal}
            <div className='OrganizationPicker-children1'>
                <img
                    src={orgData.img}
                    alt={currentOrganization}
                    width='70px'
                />
                <br/>
                {localize(lang, orgData.key)}
            </div>
            <div className='OrganizationPicker-children2'>
                <div className='OrganizationPicker-button' onClick={() => setPicking(true)}>
                    {localize(lang, 'changeOrganization')}
                </div>
            </div>
        </div>
    );
};

export default OrganizationPicker;