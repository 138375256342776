import React, { useState, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Registration.css';

import Checkout from '../../checkout/Checkout';

const Registration = () => {
    const lang = useContext(LocalizedContext);

    const [isCheckout, setCheckout] = useState(false);

    const currentDate = new Date();
    const cutoffDate = new Date('2025-06-11');

    if (currentDate >= cutoffDate) {
        return '';
    }

    const checkoutModal = isCheckout ?
        <Checkout flow='REG' cancelHandler={() => setCheckout(false)} /> : '';


    return (
        <div className='Registration-envelope'>
            {checkoutModal}
            <div className='Registration-header'>
                {localize(lang, 'registerForTournament')}
                <span className='Registration-text' onClick={() => setCheckout(true)}>{localize(lang, 'vetMCR25')}</span>
            </div>
        </div>
    );
}

export default Registration;