import React, { useContext, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { clear, addCheckoutFlow, addCheckoutContext } from './checkoutSlice';

import './Checkout.css';

import Logo from '../headerFooter/Logo';
import ProgressBar from './ProgressBar';

import CheckoutStepYou from './CheckoutStepYou';

import CheckoutStepPackage2 from './b2c/CheckoutStepPackage2';
import CheckoutSummary2 from './b2c/CheckoutSummary2';
import CheckoutThankYou2 from './b2c/CheckoutThankYou2';

import CheckoutStepTournament from './reg/CheckoutStepTournament';
import CheckoutSummary from './reg/CheckoutSummary';
import CheckoutThankYou from './reg/CheckoutThankYou';

const Checkout = props => {
    const cancelHandler = props.cancelHandler;
    const closeHandler = props.closeHandler;
    const flow = props.flow;
    const context = props.context;

    const lang = useContext(LocalizedContext);
    const currentStep = useSelector(state => state.checkout.step);

    const dispatch = useDispatch();

    const setupFlowHandler = useCallback( () => {
        dispatch(addCheckoutFlow(flow));
        if( context ) dispatch(addCheckoutContext(context));
    }, [dispatch, flow, context]);

    useEffect(() => {
        setupFlowHandler();
    }, [setupFlowHandler, dispatch, flow, context] );

    const cancelCheckout = () => {
        dispatch(clear());
        cancelHandler();
    };

    const closeCheckout = () => {
        cancelCheckout();
        if(closeHandler) closeHandler();
    }

    const isRegistration = flow === 'REG';

    var currentCheckout = '';
    switch (currentStep) {
        case 'YOU':
            currentCheckout = <CheckoutStepYou cancelCheckout={cancelCheckout}/>;
            break;

        case 'B2C_PACKAGE':
            currentCheckout = <CheckoutStepPackage2 cancelCheckout={cancelCheckout}/>;
            break;
        case 'B2C_SUMMARY':
            currentCheckout = <CheckoutSummary2 cancelCheckout={cancelCheckout}/>;
            break;
        case 'B2C_THANK_YOU':
            currentCheckout = <CheckoutThankYou2 cancelCheckout={closeCheckout}/>;
            break;

        case 'REG_EVENT':
            currentCheckout = <CheckoutStepTournament cancelCheckout={cancelCheckout}/>;
            break;
        case 'REG_SUMMARY':
            currentCheckout = <CheckoutSummary cancelCheckout={cancelCheckout}/>;
            break;
        case 'REG_THANK_YOU':
            currentCheckout = <CheckoutThankYou cancelCheckout={closeCheckout}/>;
            break;
    
        default:
    }

    const isThankYou = currentStep.endsWith('THANK_YOU');
    const isSummary = currentStep.endsWith('SUMMARY') || isThankYou;

    return (
        <div className='Checkout'>
            <div className='Checkout-selector'>
                <Logo clearContentHandler={() => {}} />
                <div className='Checkout-content'>
                    <div className='Checkout-header'>
                        { isRegistration ? localize(lang, 'registrationForm') : localize(lang, 'orderForm') }
                    </div>
                    { isSummary ? '' : localize(lang, 'addEvents')}
                </div>
                { isThankYou ? '' : <ProgressBar/> }
                {currentCheckout}
            </div>
        </div> );
}

export default Checkout;